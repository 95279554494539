import React from "react";
import { Seo } from "../../components";

const ChooseDate = () => {
	return (
		<div>
			<Seo
				title='Choose Date'
				description='Book TNT Auto Window Tinting Now! Car Tinting Never Have Been Easier, Book Online!'
			/>
			<iframe
				title='Book TNT Tinting'
				src='https://tnttinting.youcanbook.me/?noframe=true&skipHeaderFooter=true'
				id='ycbmiframetnttinting'
				className='w-full h-screen overflow-auto border-0 bg-transparent'
				frameborder='0'
				allowtransparency='true'
			></iframe>
		</div>
	);
};

export default ChooseDate;
